<template>
  <div class="home">
    <Navbar />
    <canvas id="nebulaCanvas"></canvas>
    <div class="herosection">
      <img src="@/assets/signature-nobg.png" alt="Gianluca Monte" class="signature" :class="{ 'visible': showTitle }">
      <div class="chevron" :class="{ 'hidden': isScrolled || !showChevron }" @click="scrollDown">
        Scroll down
        <br>
        ▼
      </div>
    </div>
    <div class="projects-section">
      <h2>Projects</h2>
      <div class="projects-grid">
        <div v-for="repo in repositories" :key="repo.id" class="project-card" v-intersect="onIntersect">
          <h3>{{ repo.name }}</h3>
          <p>{{ repo.description }}</p>
          <div class="project-stats">
            <span><i class="fas fa-star"></i> {{ repo.stargazers_count }}</span>
            <span><i class="fas fa-code-branch"></i> {{ repo.forks_count }}</span>
          </div>
          <a :href="repo.html_url" target="_blank" rel="noopener noreferrer">View on GitHub</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import axios from 'axios'

export default {
  name: 'HomePage',
  components: {
    Navbar
  },
  data() {
    return {
      isScrolled: false,
      showChevron: false,
      showTitle: false,
      particles: [],
      repositories: [],
    }
  },
  computed: {
  },
  methods: {
    handleScroll() {
      this.isScrolled = window.scrollY > 50;
    },
    scrollDown() {
      window.scrollTo({
        top: window.innerHeight,
        behavior: 'smooth'
      });
    },
    createNebulaBackground() {
      const canvas = document.getElementById('nebulaCanvas');
      const ctx = canvas.getContext('2d');
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;

      const colors = [
        'rgba(128, 0, 128, 0.5)',  // Dark Magenta
        'rgba(0, 128, 128, 0.5)',  // Dark Cyan
        'rgba(128, 128, 0, 0.5)',  // Dark Yellow
        'rgba(128, 50, 0, 0.5)',   // Dark Orange
        'rgba(50, 0, 128, 0.5)',   // Dark Purple
      ];

      const particleCount = window.innerWidth > 768 ? 60 : 35; // 60 for desktop, 30 for smartphone

      for (let i = 0; i < particleCount; i++) {
        this.particles.push({
          x: Math.random() * canvas.width,
          y: Math.random() * canvas.height,
          radius: Math.random() * 1.5 + 0.5,
          color: colors[Math.floor(Math.random() * colors.length)],
          vx: Math.random() * 0.2 - 0.1, // Slightly reduced speed
          vy: Math.random() * 0.2 - 0.1, // Slightly reduced speed
        });
      }

      const animate = () => {
        // Create a semi-transparent layer for the trail effect
        ctx.fillStyle = 'rgba(0, 0, 0, 0.05)';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        
        this.particles.forEach(particle => {
          ctx.beginPath();
          ctx.arc(particle.x, particle.y, particle.radius, 0, Math.PI * 2);
          ctx.fillStyle = particle.color;
          ctx.fill();

          // Increase particle speed for more noticeable movement
          particle.x += particle.vx; // Slightly reduced speed
          particle.y += particle.vy; // Slightly reduced speed

          // Wrap particles around the canvas
          if (particle.x < 0) particle.x = canvas.width;
          if (particle.x > canvas.width) particle.x = 0;
          if (particle.y < 0) particle.y = canvas.height;
          if (particle.y > canvas.height) particle.y = 0;
        });
        requestAnimationFrame(animate);
      };

      animate();
    },
    async fetchRepositories() {
      const cacheKey = 'githubRepos'
      const cacheExpiration = 60 * 60 * 1000 // 1 hour in milliseconds

      // Check if cached data exists and is still valid
      const cachedData = localStorage.getItem(cacheKey)
      if (cachedData) {
        const { data, timestamp } = JSON.parse(cachedData)
        if (Date.now() - timestamp < cacheExpiration) {
          this.repositories = data.slice(0, 6)
          return
        }
      }

      // If no valid cached data, fetch from API
      try {
        const response = await axios.get('https://api.github.com/users/gianluca5539/repos')
        this.repositories = response.data.slice(0, 6) // Display up to 6 repositories

        // Cache the fetched data
        localStorage.setItem(cacheKey, JSON.stringify({
          data: response.data,
          timestamp: Date.now()
        }))
      } catch (error) {
        console.error('Error fetching repositories:', error)
      }
    },
    onIntersect(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('fade-in');
          entry.target.classList.remove('fade-out');
        } else {
          entry.target.classList.remove('fade-in');
          entry.target.classList.add('fade-out');
        }
      });
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    setTimeout(() => {
      this.showTitle = true;
    }, 0);
    setTimeout(() => {
      this.showChevron = true;
    }, 1000);
    this.createNebulaBackground();
    this.fetchRepositories();
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
}
</script>

<style scoped>
    .home {
    display: flex;
    flex-direction: column;
    min-width: 100vw;
    min-height: 100vw;
    overflow-x: hidden;
    background-color: #000;
    position: relative;
    }
    #nebulaCanvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    }
    .herosection .signature {
        max-width: 90%;  
        max-height: 60vh;
        object-fit: contain;
        opacity: 0;
        transition: opacity 1.5s ease;
    }
    .herosection .signature.visible {
        opacity: 1;
    }
    .herosection {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100vw;
        color: #ffffff;
        position: relative;
        z-index: 1;
    }
    .chevron {
        position: absolute;
        bottom: 3dvh;
        left: 50%;
        transform: translateX(-50%);
        font-size: 1.5rem;
        text-align: center;
        cursor: pointer;
        animation: bounce 2s infinite;
        transition: opacity 0.5s ease;
        text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
        opacity: 0;
    }
    @media screen and (max-width: 768px) {
        .chevron {
            font-size: 1.2rem;
            bottom: 100px;
        }
    }
    .chevron.hidden {
        opacity: 0;
    }
    .chevron:not(.hidden) {
        opacity: 1;
    }
    @keyframes bounce {
        0%, 20%, 50%, 80%, 100% {
            transform: translateY(0) translateX(-50%);
        }
        40% {
            transform: translateY(-20px) translateX(-50%);
        }
        60% {
            transform: translateY(-10px) translateX(-50%);
        }
    }
    h1 {
        opacity: 0;
        transition: opacity 1.5s ease;
    }
    h1.visible {
        opacity: 1;
    }

    .projects-section {
      padding: 4rem 2rem;
      position: relative;
      z-index: 1;
    }

    .projects-section h2 {
      color: #ffffff;
      font-size: 2.5rem;
      text-align: center;
      margin-bottom: 2rem;
      text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
    }

    .projects-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 2rem;
      max-width: 1200px;
      margin: 0 auto;
    }

    .project-card {
      background-color: rgba(255, 255, 255, 0.15);
      border-radius: 10px;
      padding: 1.5rem;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      backdrop-filter: blur(4px);
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      cursor: default;
      opacity: 0;
      transition: opacity 0.5s ease, transform 0.5s ease, box-shadow 0.5s ease;
    }

    .project-card.fade-in {
      animation: fadeIn 1s ease-out forwards;
    }

    .project-card.fade-out {
      animation: fadeOut 1s ease-in forwards;
    }

    .project-card:hover {
      box-shadow: 0 0 15px rgba(255, 255, 255, 0.2), 0 0 20px rgba(255, 255, 255, 0.2);
    }

    .project-card h3 {
      color: #ffffff;
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
    }

    .project-card p {
      color: #cccccc;
      font-size: 1rem;
      margin-bottom: 1rem;
    }

    .project-stats {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      color: #aaaaaa;
    }

    .project-card a {
      display: inline-block;
      background-color: #4a4a4a;
      color: #ffffff;
      padding: 0.5rem 1rem;
      border-radius: 10px;
      text-decoration: none;
      transition: background-color 0.3s ease;
    }

    .project-card a:hover {
      background-color: #666666;
    }

    @media screen and (max-width: 768px) {
      .projects-section h2 {
        font-size: 2rem;
      }

      .projects-grid {
        grid-template-columns: 1fr;
      }
    }

    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(20px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }

    @keyframes fadeOut {
      from {
        opacity: 1;
        transform: translateY(0);
      }
      to {
        opacity: 0;
        transform: translateY(20px);
      }
    }
</style>
