<template>
  <div class="path">
    <Navbar />
    <canvas id="nebulaCanvas"></canvas>
    <div class="path-content" :class="{ 'fade-in': isLoaded }">
      <!-- Add explanation section -->
      <div class="graph-explanation">
        <p>Hover over the commit dots to see additional information. Each branch represents a different aspect of my life journey.</p>
      </div>
      <!-- Move the SVG graph below the explanation -->
      <svg class="git-graph" :width="graphWidth" :height="graphHeight" :viewBox="`-${graphWidth/2} 0 ${graphWidth} ${graphHeight}`">
        <g>
          <!-- Main branch line -->
          <line x1="0" y1="0" x2="0" :y2="getLastCommitY(branches[0])"
                stroke="#444" stroke-width="2" />
          <!-- Branch lines and names -->
          <g v-for="(branch, branchIndex) in branches" :key="`branch-${branchIndex}`">
            <line :x1="getBranchX(branchIndex)" :y1="branch.startY || 0" 
                  :x2="getBranchX(branchIndex)" :y2="getLastCommitY(branch)"
                  :stroke="getBranchColor(branchIndex)" stroke-width="2" />
            <path v-if="branchIndex > 0"
                  :d="getBranchPath(branch, branchIndex).path"
                  :stroke="getBranchPath(branch, branchIndex).color"
                  fill="none"
                  stroke-width="2" />
            <!-- Add branch name -->
            <text :x="getBranchNameX(branchIndex)" :y="getBranchNameY(branch)"
                  :fill="getBranchColor(branchIndex)" font-size="12"
                  :text-anchor="getBranchNameAnchor(branchIndex)" class="branch-name">
              {{ branch.name }}
            </text>
          </g>
          <!-- Commit circles and labels -->
          <g v-for="(branch, branchIndex) in branches" :key="`commits-${branchIndex}`">
            <g v-for="(commit, commitIndex) in branch.commits" :key="`commit-${branchIndex}-${commitIndex}`">
              <circle
                :cx="getBranchX(branchIndex)" :cy="commit.y"
                r="7" fill="#fff" :stroke="getBranchColor(branchIndex)"
                class="commit-dot"
                @click.stop="showPopup(commit, $event)"
                @mouseenter="showPopup(commit, $event)"
                @mouseleave="startCloseTimer"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
    <CommitPopup v-if="selectedCommit" 
                 ref="commitPopup"
                 :commit="selectedCommit" 
                 :x="popupX" 
                 :y="popupY" 
                 @close="closePopup"
                 @mouseenter="cancelCloseTimer"
                 @mouseleave="startCloseTimer"
    />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import CommitPopup from '@/components/CommitPopup.vue'

export default {
  name: 'PathPage',
  components: {
    Navbar,
    CommitPopup
  },
  data() {
    return {
      particles: [],
      branches: [
        {
          name: 'main',
          commits: [
            { created: '2002-05', name: 'Initial commit', description: 'Initializing the project', y: 50 },
            { created: '2003-05', name: '1st Birthday', description: 'A lot of crying, but some smiles too', y: 100 },
            { created: '2004-05', name: '2nd Birthday', description: 'Started talking, and never stopped', y: 150 },
            { created: '2005-05', name: '3rd Birthday', description: 'Started kindergarten', y: 200 },
            { created: '2006-05', name: '4th Birthday', description: 'Yet another year older', y: 250 },
            { created: '2007-05', name: '5th Birthday', description: 'Yet another year older', y: 300 },
            { created: '2008-05', name: '6th Birthday', description: 'Yet another year older', y: 350 },
            { created: '2009-05', name: '7th Birthday', description: 'Yet another year older', y: 400 },
            { created: '2010-05', name: '8th Birthday', description: 'Yet another year older', y: 450 },
            { created: '2011-05', name: '9th Birthday', description: 'Yet another year older', y: 500 },
            { created: '2012-05', name: '10th Birthday', description: 'Yet another year older', y: 550 },
            { created: '2013-05', name: '11th Birthday', description: 'Yet another year older', y: 600 },
            { created: '2014-05', name: '12th Birthday', description: 'Yet another year older', y: 650 },
            { created: '2015-05', name: '13th Birthday', description: 'Yet another year older', y: 700 },
            { created: '2016-05', name: '14th Birthday', description: 'Yet another year older', y: 750 },
            { created: '2017-05', name: '15th Birthday', description: 'Yet another year older', y: 800 },
            { created: '2018-05', name: '16th Birthday', description: 'Yet another year older', y: 850 },
            { created: '2019-05', name: '17th Birthday', description: 'Yet another year older', y: 900 },
            { created: '2020-05', name: '18th Birthday', description: 'Yet another year older', y: 950 },
            { created: '2021-05', name: '19th Birthday', description: 'Yet another year older', y: 1000 },
            { created: '2022-05', name: '20th Birthday', description: 'Yet another year older', y: 1050 },
            { created: '2023-05', name: '21st Birthday', description: 'Yet another year older', y: 1100 },
            { created: '2024-05', name: '22nd Birthday', description: 'Yet another year older', y: 1150 },
          ]
        },
        {
          name: 'school/kindergarten',
          startY: 200,
          commits: [
            { created: '2005-09', name: 'Started kindergarten', description: 'Excited to meet new friends', y: 200 },
            { created: '2008-06', name: 'Finished kindergarten', description: 'It was fun!', y: 360 },
          ]
        },
        {
          name: 'english/a1',
          startY: 220,
          commits: [
            { created: '2005-10', name: 'Started A1 Course', description: 'The cat is on the table', y: 220 },
            { created: '2008-06', name: 'Finished A1 Course', description: 'The cute cat is on the wood table', y: 355 },
          ]
        },
        {
          name: 'school/elementary',
          startY: 380,
          commits: [
            { created: '2008-09', name: 'Started Elementary school', description: 'Let the fun begin!', y: 380 },
            { created: '2013-06', name: 'Finished Elementary school', description: 'The fun is over!', y: 600 },
          ]
        },
        {
          name: 'english/a2',
          startY: 400,
          commits: [
            { created: '2008-11', name: 'Started A2 Course', description: 'The cute cat is on the wood table', y: 400 },
            { created: '2013-05', name: 'Finished A2 Course', description: "What's your favorite ice cream flavor?", y: 600 },
          ]
        },
        {
          name: 'school/junior-high',
          startY: 625,
          commits: [
            { created: '2013-09', name: 'Started Middle school', description: "Let's learn some more!", y: 625 },
            { created: '2016-06', name: 'Finished Middle school', description: 'Goodbye middle school!', y: 750 },
          ]
        },
        {
          name: 'english/b1',
          startY: 625,
          commits: [
            { created: '2014-10', name: 'Started English courses', description: "Now I'm learning English!", y: 625 },
            { created: '2016-06', name: 'Finished English courses', description: 'I might be able to speak it fluently!', y: 750 },
          ]
        },
        {
          name: 'school/high-school',
          startY: 775,
          commits: [
            { created: '2016-10', name: 'Started High School', description: "It's getting serious", y: 775 },
            { created: '2021-07', name: 'Finished High School', description: '100/100!', y: 1000 },
          ]
        },
        {
          name: 'english/c1',
          startY: 875,
          commits: [
            { created: '2016-10', name: 'Started C1 Courses', description: "It's getting serious", y: 875 },
            { created: '2019-05', name: 'Finished C1 Courses', description: '197/200!', y: 975 },
          ]
        },
        {
          name: 'programming/web-dev',
          startY: 875,
          commits: [
            { created: '2016-12', name: 'Started self-taught programming', description: "Let's do something useful", y: 875 },
            { created: '2021-01', name: 'Finished self-taught programming', description: 'A job is coming...', y: 985 },
          ]
        },
        {
          name: 'programming/python',
          startY: 890,
          commits: [
            { created: '2017-01', name: 'Started self-taught programming', description: "Let's do something useful", y: 890 },
            { created: '2021-01', name: 'Finished self-taught programming', description: 'A job is coming...', y: 985 },
          ]
        },
        {
          name: 'school/university',
          startY: 1025,
          commits: [
            { created: '2021-10', name: 'Started Applied Computer Science and Artificial Intelligence', description: "Very nice!", y: 1025 },
            { created: '2024-05', name: 'Still at university', description: 'About to graduate!', y: 1150 },
          ]
        },
        {
          name: 'job/messagenius',
          startY: 985,
          commits: [
            { created: '2016-10', name: 'Started working at Messagenius', description: "My first job!", y: 985 },
            { created: '2024-05', name: 'Still working at Messagenius', description: 'Very nice!', y: 1150 },
          ]
        },
        
      ],
      graphWidth: 400,
      graphHeight: 300,
      branchColors: ['#4285F4', '#DB4437', '#F4B400', '#0F9D58', '#AB47BC', '#00ACC1', '#FF7043'],
      selectedCommit: null,
      popupX: 0,
      popupY: 0,
      closeTimer: null,
      isTouch: false,
      closeTimeout: null,
      isLoaded: false,
    }
  },
  computed: {
  },
  methods: {
    createNebulaBackground() {
      const canvas = document.getElementById('nebulaCanvas');
      const ctx = canvas.getContext('2d');
      
      const resizeCanvas = () => {
        canvas.width = window.innerWidth;
        canvas.height = Math.max(window.innerHeight, this.graphHeight + 100);
      };

      resizeCanvas();

      if (this.particles.length === 0) {
        const colors = [
          'rgba(128, 0, 128, 0.5)',  // Dark Magenta
          'rgba(0, 128, 128, 0.5)',  // Dark Cyan
          'rgba(128, 128, 0, 0.5)',  // Dark Yellow
          'rgba(128, 50, 0, 0.5)',   // Dark Orange
          'rgba(50, 0, 128, 0.5)',   // Dark Purple
        ];

        const particleCount = window.innerWidth > 768 ? 60 : 35;

        for (let i = 0; i < particleCount; i++) {
          this.particles.push({
            x: Math.random() * canvas.width,
            y: Math.random() * canvas.height,
            radius: Math.random() * 1.5 + 0.5,
            color: colors[Math.floor(Math.random() * colors.length)],
            vx: Math.random() * 0.2 - 0.1,
            vy: Math.random() * 0.2 - 0.1,
          });
        }
      }

      const animate = () => {
        ctx.fillStyle = 'rgba(0, 0, 0, 0.05)';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        this.particles.forEach(particle => {
          ctx.beginPath();
          ctx.arc(particle.x, particle.y, particle.radius, 0, Math.PI * 2);
          ctx.fillStyle = particle.color;
          ctx.fill();

          particle.x += particle.vx;
          particle.y += particle.vy;

          if (particle.x < 0) particle.x = canvas.width;
          if (particle.x > canvas.width) particle.x = 0;
          if (particle.y < 0) particle.y = canvas.height;
          if (particle.y > canvas.height) particle.y = 0;
        });
        requestAnimationFrame(animate);
      };

      animate();

      window.addEventListener('resize', resizeCanvas);
    },
    getLastCommitY(branch) {
      return branch.commits[branch.commits.length - 1].y;
    },
    updateGraphDimensions() {
      this.graphWidth = Math.max(400, window.innerWidth * 0.8);
      this.graphHeight = Math.max(300, Math.max(...this.branches.flatMap(b => b.commits.map(c => c.y))) + 50);
      // Ensure the graph is wide enough to accommodate all branches
      const branchesOnEachSide = Math.ceil((this.branches.length - 1) / 2);
      const minWidth = branchesOnEachSide * 50 * 2 + 150; // Reduced from 70 to 50 for closer spacing
      this.graphWidth = Math.max(this.graphWidth, minWidth);
    },
    getBranchColor(index) {
      return this.branchColors[index % this.branchColors.length];
    },
    getBranchX(index) {
      if (index === 0) return 0; // Main branch
      const screenWidth = window.innerWidth;
      const offset = Math.min(Math.max(50, screenWidth / (this.branches.length + 3)), 80);
      
      // Limit the number of columns to a reasonable maximum
      const maxColumns = Math.floor(this.graphWidth / (2 * offset));
      
      for (let column = 1; column <= maxColumns; column++) {
        const xPosition = column * offset * (index % 2 === 0 ? 1 : -1);
        if (this.isBranchPositionAvailable(index, xPosition)) {
          return xPosition;
        }
      }
      
      // If no position is found, return a fallback position
      return (index % 2 === 0 ? 1 : -1) * maxColumns * offset;
    },
    isBranchPositionAvailable(currentIndex, xPosition) {
      for (let i = 1; i < currentIndex; i++) {
        const otherBranch = this.branches[i];
        const otherX = this.getBranchX(i);
        
        // Check if the branches overlap in both X and Y
        if (Math.abs(otherX - xPosition) < 10 && this.dobranchesOverlapInTime(this.branches[currentIndex], otherBranch)) {
          return false;
        }
      }
      return true;
    },
    dobranchesOverlapInTime(branch1, branch2) {
      const start1 = branch1.startY || branch1.commits[0].y;
      const end1 = this.getLastCommitY(branch1);
      const start2 = branch2.startY || branch2.commits[0].y;
      const end2 = this.getLastCommitY(branch2);

      return (start1 <= end2 && start2 <= end1);
    },
    getBranchNameX(index) {
      const branchX = this.getBranchX(index);
      if (index === 0) return branchX - 15; // Further to the left for main branch
      return branchX + (index % 2 === 0 ? 15 : -15); // 15px to the right or left
    },
    getBranchNameY(branch) {
      // Position the name 1/3 of the way down the branch
      const startY = branch.startY || 0;
      const endY = this.getLastCommitY(branch);
      return startY + (endY - startY) / 3;
    },
    getBranchNameAnchor(index) {
      if (index === 0) return 'end'; // Right-align for main branch
      return index % 2 === 0 ? 'start' : 'end'; // Left-align for right branches, right-align for left branches
    },
    showPopup(commit, event) {
      if (this.isTouch && event.type === 'mouseenter') return;
      
      // Clear any existing close timer
      this.cancelCloseTimer();
      
      // If it's the same commit and the popup is visible, do nothing
      if (this.selectedCommit === commit && this.$refs.commitPopup && this.$refs.commitPopup.isVisible) {
        return;
      }
      
      // If there's an existing popup, close it immediately
      if (this.selectedCommit && this.$refs.commitPopup) {
        this.$refs.commitPopup.isVisible = false;
      }
      
      // Update to the new commit immediately
      this.selectedCommit = commit;
      
      const commitRect = event.target.getBoundingClientRect();
      
      // Calculate position relative to the window
      this.popupX = commitRect.left + window.pageXOffset + commitRect.width + 10;
      this.popupY = commitRect.top + window.pageYOffset;

      // Adjust for right side overflow
      const popupWidth = 200;
      if (this.popupX + popupWidth > window.innerWidth) {
        this.popupX = commitRect.left + window.pageXOffset - popupWidth - 10;
      }

      // Ensure the popup is visible in the next tick
      this.$nextTick(() => {
        if (this.$refs.commitPopup) {
          this.$refs.commitPopup.isVisible = true;
        }
      });

      // Add click outside listener
      document.addEventListener('click', this.handleClickOutside);
    },
    closePopup() {
      if (this.selectedCommit && this.$refs.commitPopup) {
        this.$refs.commitPopup.startCloseAnimation();
      }
      
      // Use a shorter timeout for a quicker response
      this.closeTimeout = setTimeout(() => {
        this.selectedCommit = null;
        document.removeEventListener('click', this.handleClickOutside);
      }, 50); // A short delay to allow for the fade-out animation
    },
    startCloseTimer() {
      if (!this.isTouch) {
        this.closeTimer = setTimeout(() => {
          this.closePopup();
        }, 100); // Reduced from 300ms to 100ms
      }
    },
    cancelCloseTimer() {
      if (this.closeTimer) {
        clearTimeout(this.closeTimer);
        this.closeTimer = null;
      }
    },
    handleClickOutside(event) {
      if (this.selectedCommit && !event.target.closest('.commit-popup') && !event.target.closest('.commit-dot')) {
        this.closePopup();
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return `${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;
    },
    getBranchPath(branch, branchIndex) {
      const startY = branch.startY - 150;
      const endY = branch.commits[0].y;
      const startX = 0;
      const endX = this.getBranchX(branchIndex);
      
      const cp1x = startX + (endX - startX) * 0.1;
      const cp1y = startY + (endY - startY) * 0.6;
      const cp2x = startX + (endX - startX) * 0.9;
      const cp2y = startY + (endY - startY) * 0.4;

      // Create a darker shade of the branch color
      const branchColor = this.getBranchColor(branchIndex);
      const darkerColor = this.getDarkerShade(branchColor);

      return {
        path: `M ${startX} ${startY} C ${cp1x} ${cp1y}, ${cp2x} ${cp2y}, ${endX} ${endY}`,
        color: darkerColor
      };
    },
    getDarkerShade(color) {
      // Convert hex to RGB
      let r = parseInt(color.slice(1, 3), 16);
      let g = parseInt(color.slice(3, 5), 16);
      let b = parseInt(color.slice(5, 7), 16);

      // Darken the color by 30%
      r = Math.floor(r * 0.25);
      g = Math.floor(g * 0.25);
      b = Math.floor(b * 0.25);

      // Convert back to hex
      return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
    },
  },
  mounted() {
    this.updateGraphDimensions();
    this.createNebulaBackground();
    window.addEventListener('resize', () => {
      this.updateGraphDimensions();
      this.createNebulaBackground();
    });
    this.isTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    this.$nextTick(() => {
      setTimeout(() => {
        this.isLoaded = true;
      }, 100);
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateGraphDimensions);
    document.removeEventListener('click', this.handleClickOutside);
    if (this.closeTimer) {
      clearTimeout(this.closeTimer);
    }
  }
}
</script>

<style scoped>
  .path {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    background-color: #000;
    position: relative;
  }

  .path-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column; /* Change to column layout */
    justify-content: flex-start;
    align-items: center; /* Center align items */
    padding-top: 2rem;
    padding-bottom: 2rem;
    z-index: 1;
    position: relative; /* Add this line */
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .path-content.fade-in {
    opacity: 1;
  }

  #nebulaCanvas {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.1;
    }
    100% {
      opacity: 1;
    }
  }
  
  .git-graph {
    margin-top: 2rem;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .git-graph text {
    dominant-baseline: middle;
    pointer-events: none;
  }

  .commit-dot {
    cursor: pointer;
  }

  @media (hover: hover) and (pointer: fine) {
    .commit-dot:hover {
      r: 9;
    }
  }

  .branch-name {
    font-size: 12px;
    font-weight: bold;
    pointer-events: none;
    writing-mode: vertical-rl;
    text-orientation: mixed;
  }

  @media (max-width: 768px) {
    .branch-name {
      font-size: 10px; /* Smaller font size for mobile */
    }
  }

  .graph-explanation {
    text-align: center;
    color: #fff;
    margin-bottom: 1rem;
    margin-top: 1.5rem;
    padding: 0 1rem;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  .graph-explanation p {
    font-size: 0.9rem;
    line-height: 1.4;
  }

  @media (max-width: 768px) {
    .graph-explanation p {
      font-size: 0.8rem;
    }
  }
</style>
