<template>
  <nav>
    <ul>
      <li><router-link to="/" exact-active-class="active">HOME</router-link></li>
      <li><router-link to="/path" exact-active-class="active">PATH</router-link></li>
      <li><router-link to="/contact" exact-active-class="active">CONTACT</router-link></li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'NavBar'
}
</script>

<style scoped>
nav {
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

nav ul li {
  margin: 0 20px;
}

nav ul li a {
  text-decoration: none;
  color: #7d7d7d;;
  font-weight: bold;
  font-size: 14px;
}

nav ul li a:hover,
nav ul li a.router-link-active {
  color: #7d7d7d;
}

nav ul li a.active {
    color: #fff;
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.633);
}
</style>