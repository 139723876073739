<template>
  <Transition name="fade">
    <div v-if="isVisible" 
         class="commit-popup" 
         :style="{ left: x + 'px', top: y + 'px' }"
         @mouseenter="$emit('mouseenter')"
         @mouseleave="$emit('mouseleave')"
    >
      <button class="close-btn" @click="$emit('close')">&times;</button>
      <h3>{{ commit.name }}</h3>
      <p class="date">{{ formatDate(commit.created) }}</p>
      <p>{{ commit.description }}</p>
    </div>
  </Transition>
</template>

<script>
export default {
  name: 'CommitPopup',
  props: {
    commit: Object,
    x: Number,
    y: Number
  },
  data() {
    return {
      isVisible: false
    }
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
    },
    startCloseAnimation() {
      this.isVisible = false;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.isVisible = true;
    });
  }
}
</script>

<style scoped>
.commit-popup {
  position: absolute;
  background-color: rgba(30, 30, 30, 0.9);
  border: 1px solid #444;
  border-radius: 4px;
  padding: 10px;
  max-width: 200px;
  z-index: 1000;
  color: white;
  font-size: 14px;
}

.close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

h3 {
  margin-top: 0;
  margin-bottom: 5px;
}

.date {
  font-style: italic;
  color: #aaa;
  margin-bottom: 5px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>