import { createRouter, createWebHistory } from 'vue-router'
import HomePage from './views/HomePage.vue'
import PathPage from './views/PathPage.vue'
import ContactPage from './views/ContactPage.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/path',
    name: 'Path',
    component: PathPage
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactPage
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router