<template>
    <div class="contact">
      <Navbar />
      <canvas id="nebulaCanvas"></canvas>
      <div class="contact-section">
        <h2>Let's get in touch!</h2>
        <div class="contact-grid">
          <div class="contact-card" v-for="(contact, index) in contactInfo" :key="index" v-intersect="onIntersect" @click="openLink(contact.link)">
            <h3>{{ contact.name }}</h3>
            <p>{{ contact.value }}</p>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Navbar from '@/components/Navbar.vue'
  
  export default {
    name: 'ContactPage',
    components: {
      Navbar
    },
    data() {
      return {
        particles: [],
        contactInfo: [
          { name: 'GitHub', value: 'gianluca5539', link: 'https://github.com/gianluca5539' },
          { name: 'Institutional Email', value: 'monte.1982801@studenti.uniroma1.it', link: 'mailto:monte.1982801@studenti.uniroma1.it' },
          { name: 'LinkedIn', value: 'Coming Soon', link: 'https://www.linkedin.com/' },
        ]
      }
    },
    computed: {
    },
    methods: {
      createNebulaBackground() {
        const canvas = document.getElementById('nebulaCanvas');
        const ctx = canvas.getContext('2d');
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
  
        const colors = [
          'rgba(128, 0, 128, 0.5)',  // Dark Magenta
          'rgba(0, 128, 128, 0.5)',  // Dark Cyan
          'rgba(128, 128, 0, 0.5)',  // Dark Yellow
          'rgba(128, 50, 0, 0.5)',   // Dark Orange
          'rgba(50, 0, 128, 0.5)',   // Dark Purple
        ];
  
        const particleCount = window.innerWidth > 768 ? 60 : 35; // 60 for desktop, 30 for smartphone
  
        for (let i = 0; i < particleCount; i++) {
          this.particles.push({
            x: Math.random() * canvas.width,
            y: Math.random() * canvas.height,
            radius: Math.random() * 1.5 + 0.5,
            color: colors[Math.floor(Math.random() * colors.length)],
            vx: Math.random() * 0.2 - 0.1, // Slightly reduced speed
            vy: Math.random() * 0.2 - 0.1, // Slightly reduced speed
          });
        }
  
        const animate = () => {
          // Create a semi-transparent layer for the trail effect
          ctx.fillStyle = 'rgba(0, 0, 0, 0.05)';
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          
          this.particles.forEach(particle => {
            ctx.beginPath();
            ctx.arc(particle.x, particle.y, particle.radius, 0, Math.PI * 2);
            ctx.fillStyle = particle.color;
            ctx.fill();
  
            // Increase particle speed for more noticeable movement
            particle.x += particle.vx; // Slightly reduced speed
            particle.y += particle.vy; // Slightly reduced speed
  
            // Wrap particles around the canvas
            if (particle.x < 0) particle.x = canvas.width;
            if (particle.x > canvas.width) particle.x = 0;
            if (particle.y < 0) particle.y = canvas.height;
            if (particle.y > canvas.height) particle.y = 0;
          });
          requestAnimationFrame(animate);
        };
  
        animate();
      },
      onIntersect(entries) {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fade-in');
            entry.target.classList.remove('fade-out');
          } else {
            entry.target.classList.remove('fade-in');
            entry.target.classList.add('fade-out');
          }
        });
      },
      openLink(link) {
        window.open(link, '_blank', 'noopener,noreferrer');
      },
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll);
      setTimeout(() => {
        this.showTitle = true;
      }, 0);
      setTimeout(() => {
        this.showChevron = true;
      }, 1000);
      this.createNebulaBackground();
    },
    beforeUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }
  </script>
  
  <style scoped>
      .contact {
      display: flex;
      flex-direction: column;
      width: 100vw;
      min-height: 100vh;
      background-color: #000;
      position: relative;
      }
      #nebulaCanvas {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      }
      .contact-section {
        padding: 4rem 2rem;
        position: relative;
        z-index: 1;
      }
      .contact-section h2 {
        color: #ffffff;
        font-size: 2.5rem;
        text-align: center;
        margin-bottom: 2rem;
        text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
      }
      .contact-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 2rem;
        max-width: 1000px;
        margin: 0 auto;
      }
      .contact-card {
        background-color: rgba(255, 255, 255, 0.15);
        border-radius: 10px;
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        backdrop-filter: blur(4px);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        cursor: pointer; /* Change cursor to pointer to indicate clickable */
        opacity: 0;
        transition: opacity 0.5s ease, transform 0.5s ease, box-shadow 0.5s ease;
      }
      .contact-card.fade-in {
        animation: fadeIn 1s ease-out forwards;
      }
      .contact-card.fade-out {
        animation: fadeOut 1s ease-in forwards;
      }
      .contact-card:hover {
        box-shadow: 0 0 5px rgba(255, 255, 255, 0.2), 0 0 5px rgba(255, 255, 255, 0.2);
      }
      .contact-card h3 {
        color: #ffffff;
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
        margin-top: 0;
      }
      .contact-card p {
        color: #fff;
        font-size: 1rem;
        margin: 0;
      }
      @media screen and (max-width: 768px) {
        .contact-section h2 {
          font-size: 2rem;
        }
        .contact-grid {
          grid-template-columns: 1fr;
        }
      }
      @keyframes fadeIn {
        from {
          opacity: 0;
          transform: translateY(20px);
        }
        to {
          opacity: 1;
          transform: translateY(0);
        }
      }
      @keyframes fadeOut {
        from {
          opacity: 1;
          transform: translateY(0);
        }
        to {
          opacity: 0;
          transform: translateY(20px);
        }
      }
  </style>
